<template>
    <VueDragResize
      :isActive="true"
      :parentLimitation="true"
      :minw="minw"
      :minh="minh"
      :w="width || minw"
      :h="height || minh"
      :x="windowWidth / 2 - (width/2) + lOffset"
      :y="windowHeight / 8 + tOffset"
      :z="zIndex"
      :contentClass="`modal`"
      :isResizable="false"
      :class="`${initialized ? '' : 'init'}`"
      @mousedown="$emit('click')"
      @click="$emit('click')"
    >
      <div class="inner" :style="{'background-image': `url(${img})`}">
        <button class="btn" @mouseup="$emit('close')" @click="$emit('close')">X</button>
      </div>
    </VueDragResize>
</template>

<script>
import VueDragResize from "vue3-drag-resize";

export default {
  name: "MyComponent",
  components: {
    VueDragResize,
  },
  data() {
    return {
      minw: 200,
      minh: 100,
      windowHeight: window.innerHeight || 800,
      windowWidth: window.innerWidth || 1024,
      initialized: false
    };
  },
  props: {
    img: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    zIndex: {
      type: Number,
      required: true,
    },
    tOffset: {
      type: Number,
      required: true,
      default: 0
    },
    lOffset: {
      type: Number,
      required: true,
      default: 0
    },
    active: {
      type: Boolean,
      required: true,
    }
  },
  mounted() {
    // Ensure window dimensions are set
    this.windowHeight = window.innerHeight || 800;
    this.windowWidth = window.innerWidth || 1024;
    
    setTimeout(() => {
      this.initialized = true;
    }, 500);
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    }
  }
};
</script>

<style scoped>
.modal {
  background: #dcd9d5;
  border: none;
  outline: none;
  border-radius: 3px;
  cursor: move;
  /* Hardware acceleration */
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
  will-change: mask-image, box-shadow;
  
  /* Simplified shadow for better performance */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.1s ease-out, mask-image 0.1s ease-out;
  
  /* Simplified gradient with fewer color stops */
  mask-image: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
  -webkit-mask-image: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
}

.modal:has(.inner:hover),
.vdr.active .modal {
  /* Simplified active state gradient */
  mask-image: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.95) 0%,
    rgba(0, 0, 0, 0.85) 100%
  );
  -webkit-mask-image: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.95) 0%,
    rgba(0, 0, 0, 0.85) 100%
  );
  /* Single, efficient shadow */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

/* Add performance optimizations for mobile devices */
@media (max-width: 768px) {
  .modal {
    /* Even simpler shadow for mobile */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    /* Disable transitions on mobile for better performance */
    transition: none;
  }
  
  .modal:has(.inner:hover),
  .vdr.active .modal {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }
}

.init {
  animation: fadeblurinlowopac 0.5s;
}

.vdr.active:before {
  outline: none;
  border-radius: 3px;
}

.header {
  width: 100%;
  margin-top: 0 auto;
  display: flex;
  justify-content: space-between;
  border-radius: 3px 3px 0 0;
  z-index: 99;
}

.header .btn {
  float: right;
  margin: 5px;
}

.header h3 {
  margin: 5px;
}

.inner {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  height: calc(100%);
  background-size: cover;
  border-radius: 3px;
}

.btn {
  background: none;
  border: none;
  font-weight: bold;
  cursor: pointer;
  float: left;
  filter: invert(1);
  mix-blend-mode: difference;
  border-radius: 0 0 3px 0;
  z-index: 99999;
  padding: 8px 10px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
}

@media (max-width: 600px) {
  .btn {
    font-size: 1.5rem;
    height: 45px;
    width: 45px;
  }
}

@media (min-width: 601px) {
  .btn {
    font-size: 1rem;
  }
}

.btn:disabled {
  cursor: not-allowed;
}

.btn:hover {
  opacity: .6;
}
</style>
